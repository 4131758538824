import _ from 'lodash';
import axios from 'axios';
import { lists } from '@/constants/listings';

const listing = function (getRequestInfo) {
  // This is what the endpoint expects

  // Get list request
  const getRequestTemplate = {
    page: 1,
    per_page: 48,
    //search: ''
  };

  // Populate our request template with more meaningful data
  this.getRequest = Object.assign({}, getRequestTemplate, getRequestInfo);
};

listing.prototype = {
  updateExisting(existingItems, newItems) {
    let updated = _.cloneDeep(existingItems);

    newItems.forEach(item => {
      // Check if we already have this saved in our list
      const existing = updated.some(upd => upd.id === item.id);

      if (existing) {
        updated = updated.map(upd => {
          if (upd.id === item.id) {
            return item;
          }
          return upd;
        });
      } else {
        // Add on the the beginning if we didn't have it before
        updated = [
          item,
          ...updated
        ];
      }
    });
    return updated;
  },
  removeExisting(existingItems, newItems) {
    let updated = _.cloneDeep(existingItems);

    newItems.forEach(item => {
      updated = updated.filter(upd => upd.id !== item.id);
    });
    return updated;
  },
  getList(type, options) {
    return new Promise((resolve, reject) => {
      if (typeof type !== 'string') {
        console.error('API: listing.getList() requires an string as an input for the first argument.');
        reject();
      }

      const builtOptions = Object.assign({}, this.getRequest, options);

      axios.post(`${type}/filter`, builtOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  save(type, listing) {
    return new Promise((resolve, reject) => {
      if (typeof type !== 'string' || ![lists.ALLOWLIST, lists.BLOCKLIST].includes(type)) {
        console.error('API: listing.save() requires a type of either "allowlists" or "blocklists".');
        reject();
      }

      if (typeof listing !== 'object' || listing === null) {
        console.error('API: listing.save() requires an object as an input.');
        reject();
      }

      axios.post(`${type}`, listing)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveBulk(type, data) {
    return new Promise((resolve, reject) => {
      if (typeof type !== 'string' || ![lists.ALLOWLIST, lists.BLOCKLIST].includes(type)) {
        console.error('API: listing.saveBulk() requires a type of either "allowlists" or "blocklists".');
        reject();
      }

      if (typeof data !== 'object' || data === null) {
        console.error('API: listing.saveBulk() requires an object as an input.');
        reject();
      }

      // Assuming actual input reference for now
      if (!data.input) {
        console.error('API: listing.saveBulk() requires a reference to a form input.');
        reject();
      }

      if (data.input) {
        // Just get first file in list
        const csv = data.input.files[0];
        const formData = new FormData();
        formData.append('list_csv', csv);

        axios.post(`${type}/bulk-create`, formData)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      }

    });
  },
  deleteBulk(type) {
    return new Promise((resolve, reject) => {
      if (typeof type !== 'string' || ![lists.ALLOWLIST, lists.BLOCKLIST].includes(type)) {
        console.error('API: listing.deleteBulk() requires a type of either "allowlists" or "blocklists".');
        reject();
      }

      axios.delete(`${type}/bulk-destroy`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  remove(type, listing) {
    return new Promise((resolve, reject) => {
      if (typeof type !== 'string' || ![lists.ALLOWLIST, lists.BLOCKLIST].includes(type)) {
        console.error('API: listing.remove() requires a type of either "allowlists" or "blocklists".');
        reject();
      }

      if (typeof listing !== 'object' || listing === null) {
        console.error('API: listing.remove() requires an object as an input.');
        reject();
      }

      if (listing.id) {
        axios.delete(`${type}/${listing.id}`)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        console.error('API: listing.remove() requires an object with an id in order to remove the listing.');
        reject();
      }
    });
  },
  export(type) {
    return new Promise((resolve, reject) => {
      if (typeof type !== 'string' || ![lists.ALLOWLIST, lists.BLOCKLIST].includes(type)) {
        console.error('API: listing.export() requires a type of either "allowlists" or "blocklists".');
        reject();
      }

      axios.get(`${type}/export`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default listing;
